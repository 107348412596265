@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro&family=Xanh+Mono&display=swap');

*{
  /* font-family: 'Xanh Mono', monospace; */
  font-family: 'Source Serif Pro', serif;
}

main {
  height: auto;
  width: auto;
}

#home {
  background: url(/images/laptop.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 85vh;
  width: 100%;
}

#home-heading {
  color: white;
  font-weight: bolder;

}

.fas {
  color: #313f4d;
}

.social-link {
 
  color: #313f4d;
}



.social-link:hover {
  color: #4e6174;
}

#home-link:hover {
  color: white;
  opacity: 0.8;
}

#about-link:hover {
  color: white;
  opacity: 0.8;
}

#projects-link:hover {
  color: white;
  opacity: 0.8;
}

#contact-link:hover {
  color: white;
  opacity: 0.8;
}

#about-details {
  width: 70%;
  margin: auto;
}

.card {
  background-color: #e1e6e6;
  border: none;
}

.card-title {
  color: black;
}



.subtitle-wrapper {
  background-color: #2c3e50;

}

.description {
  font-size: 1.3em;
  color: #2c3e50;
}

.main-title {
  color: #2c3e50;
}

#profileImg {
  border: solid 2px #2c3e50;
}


@media(max-width:767px) {
  #profileImg {
    margin-left: 7em;
  }

}

@media(max-width:575px){
  #contact-submit{
    display: block;
    width: 100%;
  }
       
}

@media(max-width:425px) {
  #profileImg {
    margin-left: 2.5em;
  }
}